import React, { useEffect } from "react";
import "./App.css";
import MeetInstructor from "./components/meet-instructor";
import WhyChooseUs from "./components/why-us";
import HomeSection from "./components/Homepage/HomeSection";
import ContactForm from "./components/Contact";
import Footer from "./components/footer";
import Header from "./components/Header";
// import SlideshowComponent from "./components/floatingcomp";
import Coursecomponent from "./components/CoursePage";
import ComingSoon from "./components/comingsoon";
import Coming15thAug from "./components/coming15thAug"
import { BrowserRouter, Route, Routes } from "react-router-dom";
import Courses from "./components/course";
import AOS from "aos";
import "aos/dist/aos.css";
AOS.init();

const Homepage = () => {
  return (
    <div>
      <Header />
      <HomeSection />
      <WhyChooseUs />
      <MeetInstructor />
      <Coursecomponent />
      {/* <SlideshowComponent /> */}
      <ContactForm />
      <Footer />
    </div>
  );
};

function App() {
  useEffect(() => {
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  }, []);
  return (
    <div>
      <BrowserRouter>
        <Routes>
          <Route exact path="/" Component={Homepage} />
          {/* <Route exact path="/courses/webdev" Component={Courses} /> */}
          <Route exact path="/comingsoon" Component={ComingSoon} />
          {/* <Route exact path="/coming15thAug" Component={Coming15thAug}/> */}
          <Route path="/*" Component={Homepage} />
        </Routes>
      </BrowserRouter>
    </div>
  );
}

export default App;
