import React, { useRef } from 'react';
import emailjs from '@emailjs/browser';
import "./contact-form.css";

const ContactForm = () => {
  const form = useRef();
  
  const sendEmail = (e) => {
    e.preventDefault();

    const name = document.getElementById("name").value;
    const email = document.getElementById("email").value;
    const subject = document.getElementById("subject").value;
    const phone = document.getElementById("phone").value;
    const message = document.getElementById("message").value;

    if (name === "" || email === "" || subject === "" || phone === "" || message === "") {
      const alertBox = document.createElement("div");
      alertBox.id = "alert";
      alertBox.innerHTML = "Some of the fields are empty 😔 <br> Please fill them first";
      document.body.appendChild(alertBox);

      setTimeout(function () {
        alertBox.style.opacity = 1;
      }, 10);

      setTimeout(function () {
        alertBox.style.opacity = 0;
        setTimeout(function () {
          alertBox.parentNode.removeChild(alertBox);
        }, 1000);
      }, 1400);
      return;
    }

    emailjs.sendForm('service_zyaz35e', 'template_hu2yu0w', form.current, 'Tmc-KM1YmjBS6jUQ0')
      .then((result) => {
        const alertBox = document.createElement("div");
        alertBox.id = "alert";
        alertBox.innerHTML = "I will read your message shortly <br> Thanks for reaching out ♡";
        document.body.appendChild(alertBox);

        setTimeout(function () {
          alertBox.style.opacity = 1;
        }, 10);

        setTimeout(function () {
          alertBox.style.opacity = 0;
          setTimeout(function () {
            alertBox.parentNode.removeChild(alertBox);
          }, 1000);
        }, 1400);

          console.log(result.text);
          console.log("message sent");
      },
      
      (error) => {
        const alertBox = document.createElement("div");
        alertBox.id = "alert";
        alertBox.innerHTML = "An error occurred while sending the email. Please try again later.";
        document.body.appendChild(alertBox);

        setTimeout(function () {
          alertBox.style.opacity = 1;
        }, 10);

        setTimeout(function () {
          alertBox.style.opacity = 0;
          setTimeout(function () {
            alertBox.parentNode.removeChild(alertBox);
          }, 1000);
        }, 1400);
          console.log(error.text);
          console.log("error occured");
      });
  };

  return (
    <section data-aos="zoom-in"
      data-aos-easing="ease-in-out" data-aos-delay="200"
      
     data-aos-offset="0" className="contact" id="contact">
      <form ref={form} onSubmit={sendEmail} action="#">
        <h2 className="heading">Contact <span>Us ! </span></h2>
        <div  className="input-box">
          <input type="text" name="user_name" id="name" placeholder="Full Name" />
          <input type="email" name="user_email" id="email" placeholder="Email Address" />
        </div>
        <div className="input-box">
          <input type="number" name="phone_number" id="phone" placeholder="Mobile Number" />
          <input type="text" name="sub_ject" id="subject" placeholder="Email Subject" />
        </div>
        <textarea name="message" id="message" cols="30" rows="10" placeholder="Your Message"></textarea>
        <button type="submit" value="Send" className="btn">Send Message</button>
      </form>
      <div className="image-container">
        {/* <img className="cont-img" src={'/assets/images/contact-me-image-removebg.png'} alt="" /> */}
        <img className="cont-img" src="assets/images/Imgwebp/webp/contactmewebp.jpg" alt="" />
      </div>
    </section>
  );
};

export default ContactForm;




































// import React from 'react';
// import Email from 'emailjs-com'; // Make sure to install 'emailjs-com' package
// import "./contact-form.css";

// const ContactForm = () => {
//   const sendEmail = (event) => {
//     event.preventDefault();

//     const name = document.getElementById("name").value;
//     const email = document.getElementById("email").value;
//     const subject = document.getElementById("subject").value;
//     const phone = document.getElementById("phone").value;
//     const message = document.getElementById("message").value;

//     if (name === "" || email === "" || subject === "" || phone === "" || message === "") {
//       const alertBox = document.createElement("div");
//       alertBox.id = "alert";
//       alertBox.innerHTML = "Some of the fields are empty 😔 <br> Please fill them first";
//       document.body.appendChild(alertBox);

//       setTimeout(function () {
//         alertBox.style.opacity = 1;
//       }, 10);

//       setTimeout(function () {
//         alertBox.style.opacity = 0;
//         setTimeout(function () {
//           alertBox.parentNode.removeChild(alertBox);
//         }, 1000);
//       }, 1400);
//       return;
//     }

//     Email.send({
//       SecureToken: "1107fb88-13a2-4c24-9466-24e97e2b3418",
//       To: 'anuj18.work@gmail.com',
//       From: 'anuj18.work@gmail.com',
//       Subject: subject,
//       Body: "Name: " + name +
//         "<br> Email: " + email +
//         "<br> Phone no: " + phone +
//         "<br> Message: " + message
//     }).then(
//       message => {
//         const alertBox = document.createElement("div");
//         alertBox.id = "alert";
//         alertBox.innerHTML = "I will read your message shortly <br> Thanks for reaching out ♡";
//         document.body.appendChild(alertBox);

//         setTimeout(function () {
//           alertBox.style.opacity = 1;
//         }, 10);

//         setTimeout(function () {
//           alertBox.style.opacity = 0;
//           setTimeout(function () {
//             alertBox.parentNode.removeChild(alertBox);
//           }, 1000);
//         }, 1400);
//       }
//     );
//   };

//   return (
//     <section className="contact" id="contact">
//       <form ref={form} onSubmit={sendEmail} action="#">
//         <h2 className="heading">Contact <span>Us ! </span></h2>
//         <div className="input-box">
//           <input type="text" id="name" placeholder="Full Name" />
//           <input type="email" id="email" placeholder="Email Address" />
//         </div>
//         <div className="input-box">
//           <input type="number" id="phone" placeholder="Mobile Number" />
//           <input type="text" id="subject" placeholder="Email Subject" />
//         </div>
//         <textarea name="" id="message" cols="30" rows="10" placeholder="Your Message"></textarea>
//         <button type="submit" className="btn">Send Message</button>
//       </form>
//       <div className="image-container">
//         {/* <img className="cont-img" src="/assets/images/AdobeStock-WozOXajSq3-removebg-preview.png" alt="not present" /> */}
//         <img className="cont-img" src={'/assets/images/Main Adobe Contact img.png'} alt="" />
//       </div>
//     </section>
//   );
// };

// export default ContactForm;
