import React, { useState } from "react";
import { Link } from "react-router-dom";
import "./header-style.css";

const Header = () => {
  const [navActive, setNavActive] = useState(false);
  const [showCourses, setShowCourses] = useState(false);
  const [selectedCourse, setSelectedCourse] = useState("DSA"); // New state for selected course

  const courses = [
    {
      key: "DSA",
      name: "Data Structures & Algorithms",
    },
    {
      key: "WebDev",
      name: "Web Development & BlockChain",
    },
  ];

  const coursesDetails = {
    DSA: [
      "Introduction to programming",
      "C++",
      "Time and Space Complexity",
      "Array",
      "Characters and Strings",
      "Pointers",
      "Bit Manipulation",
      "Recursion",
      "Divide and Conquer",
      "Structure and OOPS",
      "Linked List",
      "C++ Standard template Library",
      "Stack",
      "Queues",
      "Trees",
      "Binary Search Tree",
      "Heaps",
      "AVL Trees",
      "Hashing and maps",
      "Greedy Algorithm",
      "Backtracking",
      "Graphs",
      "Dynamic Programming",
      "Tries",
      "Segment Trees",
    ],
    WebDev: [
      "Basic of Internet",
      "HTML",
      "CSS",
      "JAVASCRIPT",
      "React JS",
      "TypeScript",
      "NEXT JS",
      "NODE JS",
      "Express JS",
      "MONGODB and Mongoose",
      "Authentication and Authorization",
      "WebSockets and WebRTC",
      "API",
      "Advance Projects with Deployment",
      "Blockchain Introduction",
      "Cryptographic Foundations",
      "Blockchain Internal Working",
      "Ethereum and its Ecosystem",
      "Solana client-side apps",
      "Smart Contracts",
      "Solidity from Basic to Advance",
      "Rust Basic to Advance",
      "Advance Projects with Deployment",
    ],
  };

  // Toggle navbar
  const handleMenuIconClick = () => {
    setNavActive((prevNavActive) => !prevNavActive);
  };

  // Handle course selection
  const handleCourseSelection = (courseKey) => {
    setSelectedCourse(courseKey);
  };

  return (
    <header className="header">
      <img
        className="logo-img"
        src="/assets/images/Logo_PNG/Black_Logo.png"
        alt=""
        style={{ cursor: "pointer" }}
        onClick={() => (window.location.href = "/")}
      />
      <div
        className={`menu-icon ${navActive ? "cross" : ""}`}
        onClick={handleMenuIconClick}
      >
        <div className="line"></div>
        <div className="line"></div>
        <div className="line"></div>
      </div>

      <nav className={`navbar ${navActive ? "active" : ""}`}>
        <a href="#home" className="active">
          Home
        </a>
        <a href="#meet-your-instructor2">About Us</a>
        <div
          onMouseEnter={() => {
            setShowCourses(true);
          }}
          onMouseLeave={() => {
            setShowCourses(false);
          }}
          style={{ display: "inline" }}
        >
          <a href="#tutorials">Tutorials</a>
          {showCourses && (
            <div className="courses-container">
              <div className="items1-container">
                {courses.map(({ key, name }) => (
                  <div
                    className="cursor-animation"
                    key={key}
                    onClick={() => handleCourseSelection(key)} // Update selected course
                  >
                    <div className="courses-items1">{name}</div>
                    <i
                      className="fa fa-chevron-right items1-icon "
                      aria-hidden="true"
                    />
                  </div>
                ))}
              </div>
              <div className="items2-container">
  {coursesDetails[selectedCourse]?.map((data, index) => (
    <div key={data} className="courses-items2">
      {selectedCourse === "DSA" ? (
        <a
          href="https://www.youtube.com/playlist?list=PLQEaRBV9gAFsj0vi1VtpwqSeEfoIG_KSv" // Replace with your YouTube playlist link
          target="_blank"
          rel="noopener noreferrer"
        >
          {data}
        </a>
      ) : (
        <a href="https://rohittnegi.akamai.net.in/new-courses/4-web-development-block-chain" target="_blank" rel="noopener noreferrer">
          {data}
        </a>
        // <Link to={`/courses/${selectedCourse.toLowerCase()}`}>
        //   {data}
        // </Link>
      )}
      {index < coursesDetails[selectedCourse].length - 1 && (
        <hr className="hr-divider" />
      )}
    </div>
  ))}
</div>

            </div>
          )}
        </div>
        <a href="/#contact2">Contact</a>
      </nav>
      <div style={{ display: "flex" }}>
        <a
          target="_blank"
          href="https://rohittnegi.akamai.net.in/new-courses/4-web-development-block-chain"
          className="login-cta "
          rel="noreferrer"
        >
          Login
        </a>
        <a
          target="_blank"
          href="https://rohittnegi.akamai.net.in/new-courses/4-web-development-block-chain"
          className="dashboard-cta "
          rel="noreferrer"
        >
          Dashboard
        </a>
      </div>
    </header>
  );
};

export default Header;
