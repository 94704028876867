import { Link } from "react-router-dom";
import "./card.style.css";

const CardComponent = ({
  cardIcon,
  cardHeading,
  cardDescription,
  bottomBorderColor = ' #08d4ba',
}) => {
  return (
    <div className="cardContainer" style={{borderBottom : `6px solid ${bottomBorderColor}`}}>
    <Link to={'/comingsoon'}>

      <div className="card-heading">{cardHeading}</div>
      <div  className="card-description">{cardDescription}</div>
      
</Link>
    </div>
  );
};

export default CardComponent;
