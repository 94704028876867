import React from 'react';
import "./footer.css";
import { Link } from 'react-router-dom';

const Footer = () => {
  return (
    <footer className="site-footer">
      <div className="container">
        <div className="row">
          <div style={{ margin: 'auto', marginLeft: '0.3rem' }} className="col-6 col-md-3">
            <ul className="footer-links">
              <li>
                <img className="logo-footer" src="/assets/images/Logo_PNG/Black_Logo.png" alt="Company Logo" />
              </li>
              <li className='tagline'>A community of coders, making the world a better place.</li>
            </ul>
          </div>
          <div className="col-6 col-md-3">
            <h2>Quick Links</h2>
            <ul className="footer-links">
              <li><a href="#home">Home</a></li>
              <li><a href="#meet-your-instructor2">About Us</a></li>
              {/* <li><Link to="/courses/webdev">  Tutorials</Link></li> */}
              <li><Link to={'/coming15thAug'}>  Tutorials</Link></li>
              <li><a href="#contact2">Contact</a></li>
            </ul>
          </div>
          <div className="col-6 col-md-3">
            <h2>Company</h2>
            <ul className="footer-links">
              <li><Link to="/comingsoon">Terms & Conditions</Link></li>
              <li><Link to="/comingsoon">Privacy Policy</Link></li>
              <li><Link to="/comingsoon">Legal</Link></li>
            </ul>
          </div>
          <div className="col-sm-12 col-md-2">
            <h2>Don't let your dreams stay dreams</h2>
            <h2>Build your skills with us and turn them into reality</h2>
          </div>
        </div>
        <hr className="small" />
      </div>
      <div className="container">
        <div className="row">
          <div className="col-md-8 col-sm-6 col-12">
            <p>Copyright &copy; 2023 by Rohit Negi | All Rights Reserved.</p>
          </div>
          <div className="col-md-4 col-sm-6 col-12">
            <ul className="social-icons">
              <li><a href="https://www.linkedin.com/in/rohit-negi9/" target="_blank" rel="noreferrer"><i className="bx bxl-linkedin"></i></a></li>
              <li><a href="https://www.youtube.com/@Rohit_Negi" target="_blank" rel="noreferrer"><i className="bx bxl-youtube"></i></a></li>
              <li><a href="https://www.instagram.com/rohit_negi9/" target="_blank" rel="noreferrer"><i className="bx bxl-instagram-alt"></i></a></li>
            </ul>
          </div>
        </div>
      </div>
    </footer>
  );
};

export default Footer;
