import { Link } from "react-router-dom";
import "./meet.style.css";

const MeetInstructor = () => {
  return (
    <div
      data-aos="zoom-in"
      data-aos-easing="ease-in-out"
      data-aos-delay="200"
      data-aos-offset="0"
      className="instructor-container"
      id="meet-your-instructor"
    >
      <div className="header-container">
        <div className="instructor-heading">
        Learn from the  <span className="heading-color">Best!</span>
        </div>
        <div className="know-more-button">
        <Link
          to={'/comingsoon'}
        >
          Know More</Link></div>
      </div>
      <div className="bottom-separator" />
      <div className="icon-experience-parent">
        <div className="icon-text-container">
          <img
            className="icon_container"
            src={"/assets/images/rohit_negi.png"}
            alt=""
          />
          <div className="icon-title">Rohit Negi</div>
          <div className="icon-subtitle">Founder, CoderArmy</div>
        </div>
        <div className="exp-container">
          <div className="about-me">About Me</div>
          <div className="about-me-heading">
          Heartfelt Problem Solver, Instructor, and Visionary Leader, Striving to Make a Difference.
          </div>
          <div className="about-me-subheading">
          Working hard to make Technology better and more Impactful for the society.
          </div>
          <div className="exp-container-1">
           <div> Got Highest Placement in India of 2 Cr +</div>
          </div>
          <div className="exp-container-1 exp-container-2">
          <div> Post Graduate from IIT G, GATE-CSE'20 AIR - 202</div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default MeetInstructor;
