import React from "react";
import "./home-sect.css";
import { Link } from "react-router-dom";

const HomeSection = () => {
  // const imgRef = useRef(null);
  // const img2Ref = useRef(null);

  return (
    <>
      <section className="home" id="home">
        <div className="home-content">
          <h1>
          Be a part of our <span className="heading-color">Army!</span>
          </h1>
          <h3>
          A community of coders, making world a better place.
          </h3>

          <div className="social-media">
          <a
              href="https://www.linkedin.com/in/rohit-negi9/"
              target="_blank"
              rel="noreferrer"
            >
              <i className="bx bxl-linkedin"></i>
            </a>

            <a
              href="https://www.youtube.com/@Rohit_Negi"
              target="_blank"
              rel="noreferrer"
            >
              <i className="bx bxl-youtube"></i>
            </a>

            <a
              href="https://www.instagram.com/rohit_negi9/"
              target="_blank"
              rel="noreferrer"
            >
              <i className="bx bxl-instagram-alt"></i>
            </a>
          </div>

          {/* <Link
          // to={"/courses/webdev"}
            to={'https://rohittnegi.akamai.net.in/new-courses/4-web-development-block-chain'}
            className="dashbtn"
            style={{
              backgroundColor: "rgb(255, 123, 0)",
              border: "2px solid rgb(255, 123, 0)",
            }} rel="noreferrer"
            
          >
            Enroll Now
          </Link> */}

          <Link 
          to={'https://rohittnegi.akamai.net.in/new-courses/4-web-development-block-chain'} 
          target="_blank"
          className="dashbtn"
          style={{
            backgroundColor: "rgb(255, 123, 0)",
            border: "2px solid rgb(255, 123, 0)",
          }} rel="noreferrer"
          onClick={(e) => {
            e.preventDefault();
            window.open('https://rohittnegi.akamai.net.in/new-courses/4-web-development-block-chain', '_blank', 'noopener,noreferrer');
          }}
          >
            Enroll Now
          </Link>

           <a
              href="https://www.youtube.com/@Rohit_Negi"
              target="_blank" className="dashbtn" rel="noreferrer">
            Watch Video<i className="bx bx-terminal" id="termm"></i>
          </a>
        </div>
        <div className="home-img">
          {/* <img src="assets/images/babbar bhaiya home image.png" alt="" /> */}
          {/* <img src="assets/images/Coder Army Bg.png" alt="" /> */}
          {/* <img className="theimg" src="assets/images/frontpage-bgimage-removebg.png" alt="" /> */}
          {/* <img className="theimg" src="assets/images/Imgwebp/webp/frontpagewebp.webp" alt="" /> */}
          <img className="theimg" src="assets/images/Imgwebp/cwebp/frontpage-bgimage-removebg-min_1.jpg" alt="" />
        </div>
      </section>
    </>
  );
};

export default HomeSection;