import React, { useEffect } from "react";
import "./coming-soon.css";
import { Helmet } from "react-helmet";

const ComingSoon = () => {
  useEffect(() => {
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  }, []);
  const bodyStyles = {
    fontFamily: "Karla, sans-serif",
    background: "linear-gradient(to left, #5a5959, #f48217)",
    color: "#ffffff",
    paddingBottom: "32px",
    // Apply responsive styles using media queries
    "@media (max-width: 767px)": {
      paddingBottom: "30px",
    },
  };

  //   useEffect(() => {
  //     const endDate = new Date("2023-08-15T00:00:00").getTime();

  //     const updateTimer = () => {
  //       const now = new Date().getTime();
  //       const timeRemaining = endDate - now;

  //       if (timeRemaining >= 0) {
  //         const daysRemaining = Math.floor(timeRemaining / (1000 * 60 * 60 * 24));
  //         const hoursRemaining = Math.floor(
  //           (timeRemaining % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60)
  //         );
  //         const minutesRemaining = Math.floor(
  //           (timeRemaining % (1000 * 60 * 60)) / (1000 * 60)
  //         );
  //         const secondsRemaining = Math.floor(
  //           (timeRemaining % (1000 * 60)) / 1000
  //         );

  //         document.getElementById("days").innerHTML = daysRemaining;
  //         document.getElementById("hours").innerHTML = hoursRemaining;
  //         document.getElementById("minutes").innerHTML = minutesRemaining;
  //         document.getElementById("seconds").innerHTML = secondsRemaining;
  //       } else {
  //         clearInterval(interval);
  //         document.getElementById("days").innerHTML = "0";
  //         document.getElementById("hours").innerHTML = "0";
  //         document.getElementById("minutes").innerHTML = "0";
  //         document.getElementById("seconds").innerHTML = "0";
  //         alert("Thank you for your patience");
  //       }
  //     };

  //     const interval = setInterval(updateTimer, 1000);

  //     return () => {
  //       clearInterval(interval);
  //     };
  //   }, []);

  return (
    <div style={bodyStyles} className="min-vh-100 d-flex flex-column">
      <Helmet>
        <link
          href="https://fonts.googleapis.com/css?family=Karla:400,700&display=swap"
          rel="stylesheet"
        />
        <link
          href="https://unpkg.com/boxicons@2.1.4/css/boxicons.min.css"
          rel="stylesheet"
        />
        <link
          rel="stylesheet"
          href="https://cdn.materialdesignicons.com/4.8.95/css/materialdesignicons.min.css"
        />
        <link
          rel="stylesheet"
          href="https://stackpath.bootstrapcdn.com/bootstrap/4.4.1/css/bootstrap.min.css"
        />
      </Helmet>
      <main className="my-auto">
        <div className="container">
          <div className="row">
            <div className="col-md-6 section-left">
              <h1 className="page-title">
                Hold Tight <i className="bx bxs-plane-take-off"></i>
                <br />
                We are launching soon
              </h1>
              {/* <div id="timer" className="bd-cd-timer">
                <div className="time-card">
                  <span className="time-count" id="days"></span>
                  <span className="time-label">DAYS</span>
                </div>
                <div className="time-card">
                  <span className="time-count" id="hours"></span>
                  <span className="time-label">HOURS</span>
                </div>
                <div className="time-card">
                  <span className="time-count" id="minutes"></span>
                  <span className="time-label">MINUTES</span>
                </div>
                <div className="time-card">
                  <span className="time-count" id="seconds"></span>
                  <span className="time-label">SECONDS</span>
                </div>
              </div> */}
            </div>
            <div className="col-md-6 d-md-flex align-items-center">
              {/* <img src="assets/images/rocketflying.png" alt="coming soon" className="img-fluid"/> */}
              {/* <img src="assets/images/Imgwebp/webp/rocketflyingwebp.webp" alt="coming soon" className="img-fluid"/> */}
              <img src="assets/images/Imgwebp/cwebp/rocketflying-min_1.jpg" alt="coming soon" className="img-fluid"/>
            </div>
          </div>
        </div>
      </main>
      <footer className="text-center">
        <nav className="footer-social-links">
          <a href="https://www.youtube.com/@Rohit_Negi" target="_blank"className="social-link" rel="noreferrer">
            <i className="mdi mdi-youtube"></i>
          </a>
          <a href="https://www.linkedin.com/in/rohit-negi9/" target="_blank" className="social-link" rel="noreferrer">
            <i className="mdi mdi-linkedin"></i>
          </a>
          <a href="https://www.instagram.com/rohit_negi9/" target="_blank" className="social-link" rel="noreferrer">
            <i className="mdi mdi-instagram"></i>
          </a>
        </nav>
        <p className="footer-text mb-0">
          Copyright &copy; 2023 by Rohit Negi | All Rights Reserved.
        </p>
      </footer>
    </div>
  );
};

export default ComingSoon;
