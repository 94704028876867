import React from 'react';
import "./courses.css";
import { Link } from 'react-router-dom';

const Coursecomponent = () => {
    return (
        <>
        <section data-aos="zoom-in"
      data-aos-easing="ease-in-out" data-aos-delay="200"
      
     data-aos-offset="0" className="portfolio" id="portfolio">
            <h2 className="p_heading">What Will You Get ?</h2>
  <div className="portfolio-wrapper">
    <div className="portfolio-container">
      <div className="portfolio-box">
        <img src="assets/images/webdev.jpg" alt="" />
        <div className="portfolio-layer">
          <h4>Web Development & Blockchain</h4>
          <p>Master the art of web development with real-world projects and industry secrets—your gateway to building powerful, modern websites!</p>
          {/* <Link to={'/courses/webdev'}> */}
          {/* <Link to={'https://rohittnegi.akamai.net.in/new-courses/4-web-development-block-chain'}> */}
          <a href="https://rohittnegi.akamai.net.in/new-courses/4-web-development-block-chain" target="_blank" rel="noopener noreferrer">
          <i className='bx bx-link-external'></i>
          </a>
          {/* </Link> */}
        </div>
      </div>
    </div>

    <div className="portfolio-container">
      <div className="portfolio-box">
        <img src="assets/images/dsaPaid.png" alt="" />
        <div className="portfolio-layer">
          <h4>DSA (Paid)</h4>
          <p>This course is everything you need to take your DSA skills to the next level. We will start from the very basics and move towards intermediate and advanced topics with practical and easy to understand real-world examples.</p>
            <a href="https://rohittnegi.akamai.net.in/new-courses/5-data-structure-and-algorithm-course" target="_blank" rel="noopener noreferrer">
              <i className='bx bx-link-external'></i>
          </a>
        </div>
      </div>
    </div>

    <div className="portfolio-container">
      <div className="portfolio-box">
        <img src="assets/images/dsal.png" alt="" />
        <div className="portfolio-layer">
          <h4>DSA</h4>
          <p>This course is everything you need to take your DSA skills to the next level. We will start from the very basics and move towards intermediate and advanced topics with practical and easy to understand real-world examples.</p>
          <a href="https://www.youtube.com/playlist?list=PLQEaRBV9gAFsj0vi1VtpwqSeEfoIG_KSv" target="_blank" rel="noopener noreferrer">
            <i className='bx bx-link-external'></i>
          </a>
        </div>
      </div>
    </div>
  </div>
        
        <section className="floatsec" id="floatsec">
        {/* <h1 className="heading">Perks</h1> */}
        <div className="merged-containerfc">
          <h1 className="merged-heading">
            Get a chance to be placed at top PBC's
          </h1>
          <div className="containerfc">
            <div className="slide-containerfc">
              <div className="slide-image">
                <img src="assets/images/Sliding/meta.png" alt="" />
              </div>
              <div className="slide-image">
                <img src="assets/images/Sliding/amazon.png" alt="" />
              </div>
              <div className="slide-image">
                <img src="assets/images/Sliding/salesforce.png" alt="" />
              </div>
              <div className="slide-image">
                <img src="assets/images/Sliding/netflix.png" alt="" />
              </div>
              <div className="slide-image">
                <img src="assets/images/Sliding/google.png" alt="" />
              </div>
              <div className="slide-image">
                <img src="assets/images/Sliding/micro.png" alt="" />
              </div>

              <div className="slide-image">
                <img src="assets/images/Sliding/meta.png" alt="" />
              </div>
              <div className="slide-image">
                <img src="assets/images/Sliding/amazon.png" alt="" />
              </div>
              <div className="slide-image">
                <img src="assets/images/Sliding/salesforce.png" alt="" />
              </div>
              <div className="slide-image">
                <img src="assets/images/Sliding/netflix.png" alt="" />
              </div>
              <div className="slide-image">
                <img src="assets/images/Sliding/google.png" alt="" />
              </div>
              <div className="slide-image">
                <img src="assets/images/Sliding/micro.png" alt="" />
              </div>
            </div>
          </div>
          <div className="containerfc">
            <div className="slide-containerfc reverse">
              <div className="slide-image">
                <img src="assets/images/logo-comp/uber.png" alt="" />
              </div>
              <div className="slide-image">
                <img src="assets/images/logo-comp/razorpay-icon.png" alt="" />
              </div>
              <div className="slide-image">
                <img src="assets/images/logo-comp/Zomato_logo_PNG1.png" alt="" />
              </div>
              <div className="slide-image">
                <img src="assets/images/logo-comp/CRED-LOGO2.png" alt="" />
              </div>
              <div className="slide-image">
                <img src="assets/images/logo-comp/Swiggy.png" alt="" />
              </div>
              <div className="slide-image">
                <img src="assets/images/logo-comp/share-chat.png" alt="" />
              </div>
              
              <div className="slide-image">
                <img src="assets/images/logo-comp/uber.png" alt="" />
              </div>
              <div className="slide-image">
                <img src="assets/images/logo-comp/razorpay-icon.png" alt="" />
              </div>
              <div className="slide-image">
                <img src="assets/images/logo-comp/Zomato_logo_PNG1.png" alt="" />
              </div>
              <div className="slide-image">
                <img src="assets/images/logo-comp/CRED-LOGO2.png" alt="" />
              </div>
              <div className="slide-image">
                <img src="assets/images/logo-comp/Swiggy.png" alt="" />
              </div>
              <div className="slide-image" >
                <img src="assets/images/logo-comp/share-chat.png" alt="" />
              </div>
            </div>
          </div>
          <div id="contact2"></div>
        </div>
      </section>
      </section>
        </>
    );
};

export default Coursecomponent;