import CardComponent from "../cards";
import "./why-us.style.css";

const CardArray = [
  {
    title: "Data Structure",
    subtitle:
      "DSA (Data Structures and Algorithms) are essential concepts in computer science, focused on efficient data organization and problem-solving techniques. They provide tools to store, retrieve, and process data, influencing the efficiency of software applications. Mastering DSA enhances coding skills and optimizes algorithmic solutions.",
    icon: "",
    borderColor: "#08d4ba",
  },
  {
    title: "C++",
    subtitle:
      "C++ is a high-performance programming language known for its versatility and efficiency. It combines features like object-oriented programming and low-level memory control, making it ideal for diverse applications. Its extensive standard library and wide industry usage make C++ a popular choice for software development.",
    icon: "",
    borderColor: "#08d4ba",
  },
];

const WhyChooseUs = () => {
  return (
    <div id="why-choose-us" className="why-us-container">
      <div className="heading">Articles</div>
      <div className="sub-heading">
      Do you know text beats Videos in terms of learning speed.
      </div>
      <div className="cards-container">
        {CardArray.map((item) => {
          return (
            <CardComponent
              cardIcon={""}
              cardHeading={item.title}
              cardDescription={item.subtitle}
              bottomBorderColor={item.borderColor}
            />
          );
        })}
      </div>
      <span id="meet-your-instructor2"></span>
    </div>
  );
};

export default WhyChooseUs;
